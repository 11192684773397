
const paper = () => {

  const paper = document.querySelector(".facts__container");
  const wid = document.documentElement.clientWidth;

  if(paper) {
    const image = paper.querySelector(".facts__img");

    window.addEventListener("scroll", () => {
      let distanceTop = paper.getBoundingClientRect().top;
      let distanceBottom = paper.getBoundingClientRect().bottom;
      let distanceLeft = paper.getBoundingClientRect().left;

      if (distanceTop < 0 && distanceBottom > 920) {
        if(!image.classList.contains("fixed")) {
          image.classList.add("fixed");
          image.classList.remove("fixed-bottom");

          if(wid < 1024) {
            image.style.left = `calc(${distanceLeft}px + 0rem)`;
          } else {
            image.style.left = `calc(${distanceLeft}px + 12rem)`;
          }
        }
      } else if(distanceTop < 0 && distanceBottom < 920) {
        if(image.classList.contains("fixed")) {
          image.classList.remove("fixed");
          image.classList.add("fixed-bottom");
          image.style.left = ``;
        }
      } else {
        image.classList.remove("fixed");
        image.classList.remove("fixed-bottom");
        image.style.left = ``;
      }
    })
  }






}

export default paper;