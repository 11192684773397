const gamesModal = () => {

  let btn1 = document.querySelector(".games__more_1");
  let btn2 = document.querySelector(".games__more_2");
  let btn3 = document.querySelector(".games__more_3");
  let btn4 = document.querySelector(".games__more_4");

  let modal1 = document.querySelector(".modal-games_1");
  let modal2 = document.querySelector(".modal-games_2");
  let modal3 = document.querySelector(".modal-games_3");
  let modal4 = document.querySelector(".modal-games_4");

  function modalz (btn, modal) {
    let modalInner = modal.querySelector(".modal-games__text");
    let close = modal.querySelector(".modal-games__close");

    btn.addEventListener("click", (e) => {
      e.preventDefault();
      modal.classList.add("open");
    });

    modal.addEventListener("click", (event) => {
      const clickedElementsTree = event.composedPath();

      if (clickedElementsTree.includes(modalInner)) {
        return;
      } else {
        modal.classList.remove("open");
      }
    })

    close.addEventListener("click", (e) => {
      e.preventDefault();

      if (modal.classList.contains("open")) {
        modal.classList.remove("open");
      }
    })
  }

  if(btn1) {
    modalz(btn1, modal1);
    modalz(btn2, modal2);
    modalz(btn3, modal3);
    modalz(btn4, modal4);
  }


}

export default gamesModal;