// Проверить, подключился ли js на страницу

const checkJs = () => {
  document.documentElement.classList.remove("no-js");







}

export default checkJs;