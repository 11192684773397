const modals = () => {

  let btns = document.querySelectorAll(".show-modal");
  let modal = document.querySelector(".modal");
  let close = document.querySelector(".modal__close");

  if(btns) {

    for(let item of btns) {
      item.addEventListener("click", (e) => {
        e.preventDefault();

        if(modal) {
          modal.classList.add("active");
        }

      });
    }

  }

  if (close) {
    close.addEventListener("click", () => {
      modal.classList.remove("active");
    })
  }





}

export default modals;
