const slider = () => {

var owl = $('.slider__owl');

 owl.owlCarousel({
    margin: 0,
    loop: true,
    items: 1,
    dots: false,
    nav: true,
    autoWidth: false,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    smartSpeed: 500,
    responsive: {
      768: {
        items: 2,
        margin: 0,
        nav: true
      },
      1100: {
        items: 3,
        margin: 0,
        nav: true
      },
      1440: {
        items: 4,
        margin: 0,
        nav: true
      }
    }
  });

  setTimeout(() => {
    owl.trigger('refresh.owl.carousel', [100]);
  }, 100);

  window.addEventListener("load", () => {
    owl.trigger('refresh.owl.carousel', [100]);
  });



  var owl2 = $('.slider-mobile__owl');

 owl2.owlCarousel({
    margin: 0,
    loop: true,
    items: 1,
    dots: false,
    nav: true,
    autoWidth: false,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    smartSpeed: 500,
    responsive: {
      768: {
        items: 2,
        margin: 0,
        nav: true
      },
      1100: {
        items: 3,
        margin: 0,
        nav: true
      },
      1440: {
        items: 4,
        margin: 0,
        nav: true
      }
    }
  });

  setTimeout(() => {
    owl2.trigger('refresh.owl.carousel', [100]);
  }, 100);

  window.addEventListener("load", () => {
    owl2.trigger('refresh.owl.carousel', [100]);
  });

}

export default slider;