import checkJs from "./modules/checkJs";
import date from "./modules/date";
import { useDynamicAdapt } from './modules/dynamicAdapt'
import gamesModal from "./modules/gamesModal";
import mapRealize from "./modules/map";
import mask from "./modules/mask";
import menu from "./modules/menu";
import modalForm from "./modules/modalForm";
import modals from "./modules/modals";
import paper from "./modules/paper";
import birtSlider from "./modules/photos";
import reviews from "./modules/reviews";
import slider from "./modules/slider";

window.addEventListener("DOMContentLoaded", () => {

  try {
    useDynamicAdapt();
    checkJs();
    menu();
    gamesModal();
    paper();
    birtSlider();
    reviews();
    slider();
    date();
    modals();
    mapRealize();
    mask();
    modalForm();
  } catch (error) {
    console.log(error);
  }

  new WOW().init();

  Fancybox.bind('[data-fancybox]', {
      // Custom options
  });

});



// try {
//   date();
// } catch (error) {
//   console.log(error);
// }

// try {
//   modals();
// } catch (error) {
//   console.log(error);
// }

// try {
//   scroll();
// } catch (error) {
//   console.log(error);
// }

// try {
//   mask();
// } catch (error) {
//   console.log(error);
// }

