const mapRealize = () => {

  // Яндекс карта
  // https://yandex.ru/dev/maps/jsapi/doc/2.1/quick-start/index.html
  // https://developer.tech.yandex.ru/services/3
  function ready() {

    if(document.querySelector("#map")) {
      ymaps.ready(init);

      function init() {

        var myMap = new ymaps.Map("map", {
          center: [56.851047, 35.863217],
          zoom: 14,
          behaviors: ["drag"],
          controls: ['zoomControl', 'fullscreenControl']
        }, {
          searchControlProvider: 'yandex#search'
        });

        var placemark = new ymaps.Placemark([56.851047, 35.863217], {
          balloonContentHeader: 'ТЦ Морозовский',
          balloonContentBody: "левое крыло, 5 этаж"
        }, {
          preset: 'islands#icon',
          iconColor: '#030434'
        });

        myMap.geoObjects.add(placemark);

        //myMap.setBounds(myMap.geoObjects.getBounds());
      }

      // Ищем и удаляем мусор с карты (кроме логотипа и копирайта)
      function delets() {
        let elem = document.querySelector(".ymaps-2-1-79-gototech");

        if (elem) {
          elem.parentNode.removeChild(elem);
        }
      }

      setTimeout(delets, 2000);
    }

  }

  ready();
}

export default mapRealize;