const menu = () => {
    const btnBurger = document.querySelector(".header__burger");
    const btnClose = document.querySelector(".header__close");
    const headerMobile = document.querySelector(".header__mobile");
    const links = document.querySelectorAll(".header__menu__link");
    const linksFooter = document.querySelectorAll(".footer__top-link");

    btnBurger.addEventListener("click", () => {
        headerMobile.classList.add("active");
    })

    btnClose.addEventListener("click", () => {
        headerMobile.classList.remove("active");
    })



    for(let item of links) {
        item.addEventListener("click", (e) => {

            if (headerMobile.classList.contains("active")) {
                headerMobile.classList.remove("active");
            }

            const linkHref = item.getAttribute("href");
            const section = document.querySelector(linkHref);
            if (linkHref.indexOf('#') > -1) {
                e.preventDefault();
            }

            if (section) {
                section.scrollIntoView({
                block: "start",
                behavior: "smooth"
                })
            } else {
                window.location.replace(`/${linkHref}`);
            }
        })
    }

    for(let item of linksFooter) {
        item.addEventListener("click", (e) => {

            const linkHref = item.getAttribute("href");
            const section = document.querySelector(linkHref);
            if (linkHref.indexOf('#') > -1) {
                e.preventDefault();
            }

            if (section) {
                section.scrollIntoView({
                block: "start",
                behavior: "smooth"
                })
            } else {
                window.location.replace(`/${linkHref}`);
            }
        })
    }


}

export default menu;